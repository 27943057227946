"use client";
import { useTranslations } from "next-intl";
import { usePathname, useRouter } from "next/navigation";
import { useEffect, useRef, useState } from "react";
import img1 from "../public/hero-img.webp";
import img2 from "../public/hero-img2.webp";
import img3 from "../public/hero-img3.webp";
import { Button } from "./ui/button";
import { Cover } from "./ui/cover";
import { HeroHighlight } from "./ui/hero-highlight";

const data = [
  {
    type: "img1",
    title: "we_provide_hero",
    desc: "we_provide_hero_p",
    des: "we_provide_hero_span",
    img: img1,
  },
  {
    type: "img2",
    title: "our_mission",
    desc: "our_mission_content",
    img: img2,
  },
  {
    type: "img3",
    title: "our_vision",
    desc: "our_vision_content",
    img: img3,
  },
];

function NewHero() {
  const [isDark, setIsDark] = useState(false);
  useEffect(() => {
    const root = window.document.documentElement;
    const theme = localStorage.getItem("theme") || "light";
    setIsDark(theme === "dark");

    root.classList.toggle("dark", theme === "dark");
  }, []);
  const t = useTranslations("Index");
  const pathname = usePathname();
  const lang = pathname.includes("ar") ? "ar" : "en";
  const router = useRouter();

  const [currentIndex, setCurrentIndex] = useState(0);
  const slideRefs = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    const options = {
      threshold: 0.5,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const index = Number(entry.target.getAttribute("data-index"));

          setCurrentIndex(index);
        }
      });
    }, options);

    const currentSlideRefs = slideRefs.current;

    currentSlideRefs.forEach((slide) => {
      if (slide) observer.observe(slide);
    });

    return () => {
      currentSlideRefs.forEach((slide) => {
        if (slide) observer.unobserve(slide);
      });
    };
  }, []);

  const scrollToSection = (id: string) => {
    const element = document.getElementById(id);
    element?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      {/* <Nav /> */}
      {/* <div className="relative  flex flex-col items-center justify-between overflow-hidden bg-[#FCFCFC] dark:bg-[#1A1A1A] pb-4"> */}
      <HeroHighlight
        containerClassName=""
        className="w-full h-screen flex flex-col gap-4 items-center justify-center pt-[1%]"
      >
        <div className="md:w-1/2 text-center md:py-0 flex flex-col gap-8 justify-center items-center">
          <h1 className="font-montserrat font-semibold dark:text-white lg:text-[52px] w-full md:text-6xl text-5xl md:max-w-xl">
            {t("we_provide_hero")} <br />
            <span className="font-montserrat md:text-6xl text-[64px] md:max-w-xl bg-clip-text text-transparent bg-gradient-to-r from-[#014CAF] to-[#217AF0] dark:from-[#0066ED] dark:to-[#217AF0]">
              <Cover>{t("we_provide_hero_span")}</Cover>
            </span>
          </h1>

          <p className="text-black w-[78%] dark:text-[#AAAAAA]">
            {t("we_provide_hero_p")}
          </p>
          <Button
            className="capitalize mx-auto bg-primary font-semibold rounded-full p-7  flex text-xl border-transparent justify-center items-center "
            onClick={() => router.push(`${lang}/contact-us`)}
          >
            {t("free_consulting")}
          </Button>
          {/* <FloatingDock items={links} desktopClassName="-rotate-90" /> */}
        </div>
        {/* <LayoutOpations /> */}
        {/* <div
          dir="ltr"
          className="flex justify-between w-[500px] items-center rotate-90 fixed -left-48 top-80"
        >
          <ModeToggle />
          <div className="flex justify-center items-center gap-6">
            <Linkedin
              size={20}
              className="dark:text-white -rotate-90 fill-black dark:fill-white"
            />
            <Facebook
              size={20}
              className="dark:text-white -rotate-90 fill-black dark:fill-white"
            />
            <Instagram size={20} className="dark:text-white -rotate-90" />
            <hr className="w-[1px] h-20 bg-black dark:bg-white -rotate-90 translate-x-10" />
            <h2 className="font-bold uppercase translate-x-20 dark:text-white">
              {t("followUs")}
            </h2>
          </div>
        </div>
        <ToggleLanguage /> */}

        {/* <div
          className="flex flex-col gap-2 items-center  text-blue-800 mt-auto pt-10 hover:cursor-pointer"
          onClick={() => scrollToSection("welcome")}
        >
          <p className="dark:text-[#AAAAAA]">{t("scroll_down")}</p>
          <div className="flex flex-col justify-center items-center">
            <Image
              src={isDark ? "/darkVector.png" : "/teenyicons_mouse-outline.svg"}
              width={2000}
              height={2000}
              alt="hero"
              className="w-8"
            />
            <Image
              src={isDark ? "/darkLine.png" : "/line1.svg"}
              width={2000}
              height={2000}
              alt="hero"
              className="w-[1px] h-10 object-cover"
            />
          </div>
        </div> */}
      </HeroHighlight>

      {/* </div> */}
    </>
  );
}

export default NewHero;
