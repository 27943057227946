import { cn } from "@/lib/utils";
import { Loader2 } from "lucide-react";

const SectionLoader = ({ className }: { className?: string }) => (
  <div
    className={cn(
      "flex justify-center items-center h-screen bg-muted",
      className
    )}
  >
    <Loader2 className="h-8 w-8 animate-spin text-foreground" />
  </div>
);

export default SectionLoader;
