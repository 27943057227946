"use client";
import { useTranslations } from "next-intl";
import Footer from "@/components/footer";
import dynamic from "next/dynamic";
import { useEffect, useState } from "react";
import SectionLoader from "@/components/section-loader";
// import Welcome from "@/components/welcome";
// import Services from "@/components/services";
// import Box from "@/components/box";
// import OurProducts from "@/components/our-products";
// import WhoWeAre from "@/components/whoWeAre";
// import Problems from "@/components/problems";
// import Customize from "@/components/customize";
// import Solutions from "@/components/soultions";
// import New from "@/components/new";
import NewHero from "@/components/newHero";
// import AboutQuestuins from "@/components/aboutQuestuins";

const Welcome = dynamic(() => import("@/components/welcome"), {
  loading: () => <SectionLoader />,
});
const Services = dynamic(() => import("@/components/services"), {
  loading: () => <SectionLoader />,
});
const Box = dynamic(() => import("@/components/box"), {
  loading: () => <SectionLoader />,
});
const OurProducts = dynamic(() => import("@/components/our-products"), {
  loading: () => <SectionLoader />,
});
const WhoWeAre = dynamic(() => import("@/components/whoWeAre"), {
  loading: () => <SectionLoader />,
});
const Problems = dynamic(() => import("@/components/problems"), {
  loading: () => <SectionLoader />,
});
const Customize = dynamic(() => import("@/components/customize"), {
  loading: () => <SectionLoader />,
});
const Solutions = dynamic(() => import("@/components/soultions"), {
  loading: () => <SectionLoader />,
});
const New = dynamic(() => import("@/components/new"), {
  loading: () => <SectionLoader />,
});

const AboutQuestuins = dynamic(() => import("@/components/aboutQuestuins"), {
  loading: () => <SectionLoader />,
});

export default function Index() {
  const t = useTranslations("Index");
  const [showSections, setShowSections] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSections(true);
    }, 300);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <NewHero />
      {showSections && (
        <>
          <Welcome />
          <Services />
          <Box />
          <WhoWeAre />
          <Problems />
          <Customize />
          <OurProducts />
          <Solutions />
          <New />
          <AboutQuestuins />
        </>
      )}
      <Footer />
    </>
  );
}
